<template>
  <el-row style="height: calc(100vh - 40px); overflow-y: auto">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="事件类型:">
              <el-input
                clearable
                v-model="searchForm.eventCode"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="事件描述:">
              <el-input
                clearable
                v-model="searchForm.desp"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="排课id:">
              <el-input
                clearable
                v-model="searchForm.lessonId"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="5" :md="24">
              <el-form-item label="来源:">
                <el-input
                  clearable
                  v-model="searchForm.source"
                  placeholder="请输入"
                  class="w100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="5" :md="24">
              <el-form-item label="上传日志作者:">
                <el-input
                  clearable
                  v-model="searchForm.operator"
                  placeholder="请输入"
                  class="w100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="24">
              <el-form-item label="查询日期：">
                <date-picker
                  ref="dataPicker"
                  :dateTime="dateTime"
                  class="w100"
                  @updateView="datetTimeGet"
                ></date-picker>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="true"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>RTM日志数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="getInteractionLogList"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="dataList"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '发生时间'">
              <span>{{scope.row.createTime.substring(0,19)}}</span>

            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      >
      </el-pagination>
    </el-card>
  </el-row>
</template>

<script>
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import datePicker from "@/components/date-picker";
import {
  getInteractionLogList,
} from "@/api/system/role";
// 默认展示列

const baseColSetting = [
  {
    label: "排课id",
    prop: "lessonId",
    state: true,
    align: "left",
    width: "150px",
  },
  {
    label: "事件类型",
    prop: "eventCode",
    state: true,
    align: "left",
    width: "150px",
  },
  {
    label: "事件描述",
    prop: "desp",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "发生时间",
    prop: "createTime",
    state: true,
    sortable:true,
    align: "center",
    width: "150",
  },
  {
    label: "来源",
    prop: "source",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "上传人",
    prop: "operator",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  components: {
    TextButton,
    SearchQuery,
    datePicker
  },
  data() {
    return {
      advanced: false,
      minStartTime:'',
      maxStartTime:'',
      dateTime: [],
      dataList: [],
      tableKey: 1,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10,20,30,50,80,100,10000],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      searchForm: {
        eventCode: '',
        desp: '',
        operator:'',
        lessonId:'',
        source:''
      },

      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getInteractionLogList();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    updateView(data) {
      this.advanced = data;
    },
    search() {
      this.pagination.pageNum = 1
      this.getInteractionLogList()
    },
    datetTimeGet(data) {
      if (data === null) {
        this.minStartTime = "";
        this.maxStartTime = "";
      } else {
        this.minStartTime = data[0];
        this.maxStartTime = data[1];
      }
    },
    async getInteractionLogList() {
        const response = await getInteractionLogList({
          needCount:true,
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          eventCode:this.searchForm.eventCozde,
          lessonId:this.searchForm.lessonId,
          source:this.searchForm.source,
          desp:this.searchForm.desp,
          st:this.minStartTime,
          et:this.maxStartTime,
          operator:this.searchForm.operator
        })
      this.dataList = response.body.list
      this.pagination.total = response.body.total
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getInteractionLogList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getInteractionLogList();
    },
    /**
     * 重置表单
     */
    clearQuery() {
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.searchForm = {};
      this.$refs.dataPicker.time = [];
      this.maxStartTime = "";
      this.minStartTime = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/container.scss";
</style>
